body {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; */
  margin: 0;
  background-color: #000000;
}
.h-100vh {
  height: 50vh;
}
/* .chat-container {
    background: #eaf0ff 0% 0% no-repeat padding-box;
    border-radius: 12px 12px 0px 0px;

    opacity: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: hidden;
  } */

.chat-container {
  /* position: absolute;
   top: 0;
   right: 0; */
  height: 100%;
  width: 100%;
  /* max-width: 400px;
   width: 100%; */
  /* max-height: 80%; */
  background: #eaf0ff;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* overflow-y: auto; */
}

.chat-messages {
  /* min-height: 300px;
   max-height: 400px; */
  /* overflow-y: auto; */
  padding-bottom: 20px;
  background-color: #f7f9ff;
}

.message {
  /* margin: 8px; */
  padding: 10px 15px;
  border-radius: 8px;
}

.message.received {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.message.sent {
  background-color: #e2f6ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: right;
}

.message h4 {
  margin: 0;
  color: #333;
  font-size: 16px;
}

/* .message-form {
   display: flex;
} */

.message-form textarea {
  /* flex: 1 1; */
  width: 90%;
  color: #6993ff;
  /* padding: 10px; */
  border: 0px solid #ccc;
  /* border-radius: 5px; */
  outline: none;
  background: #eaf0ff;
  /* width: 100%; */
  /* padding: 6px;  */
  /* line-height: 18px;  */
  resize: none;
  border-radius: 4px;
  /* border: 1px solid #ccc; */
  font-size: 14px; /* Adjust font size if needed */
  /* overflow: hidden;  */
}
.footerChat {
  /* width: 91%; */
  color: #6993ff;
  padding: 0px 10px;
  border: 0px solid #ccc;
  border-radius: 5px;
  outline: none;
  background: #eaf0ff;
}
.userName {
  background: #2e4993;
  color: #fff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.userText {
  background: #f4f4f5;
  border-radius: 10px;
  padding: 10px;
  letter-spacing: 0.32px;
  color: #000;
}
.user {
  background: #eaf0ff !important;
}
::placeholder {
  color: #6993ff;
  font-size: 12px;
}
textarea::-webkit-scrollbar {
  width: 0.2em;
}
.message-form button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* margin-top: 6px; */
  background: #6993ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.message-form button:hover,
.message-form button:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25);
  outline: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* margin-top: 6px; */
  background: #6993ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.userTextLast {
  background: #ffffff;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 10px;
  padding: 10px 15px;
  width: fit-content !important;
}

.close-button {
  /* Add your styles for the close button */
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
.headerChat {
  /* background: #EAF0FF; */
  background: #eaf0ff 0% 0% no-repeat padding-box;
  border-radius: 12px 12px 0px 0px;
}
.headerChat {
  background: #eaf0ff;
  border-radius: 12px 12px 0px 0px;
}
